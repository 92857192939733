<template>
  <div eagle-form-item="switch" class="mt-0">
    <v-switch
      inset
      dense
      color="green"
      :label="switchLabel"
      :true-value="values.true"
      :false-value="values.false"
      :value="values"
      :readonly="readonly"
      :disabled="disabled"
      :error="!!error"
      :error-messages="error"
      :rules="vuetifyRules"
      :autofocus="focus"
      v-model="data"
    ></v-switch>
  </div>
</template>

<script lang="babel" type="text/babel">
import formItemMixin from '@/components/form/mixins/formItemMixin'
export default {
  mixins: [formItemMixin],
  mounted() {
    this.init()
  },
  methods: {
    init() {
      const isTrueValue = this.storeData === this.values.true
      this.data = isTrueValue ? window.eagleLodash.cloneDeep(this.storeData) : this.values.false
    },
  },
  computed: {
    switchLabel() {
      if (typeof this.formDataItem.switchLabel != 'function') {
        return null
      }
      return this.formDataItem.switchLabel(this.data)
    },
    defaultValues() {
      if(this.formDataItem.isNumberType) {
        return {
          true: 1,
          false: 0
        }
      }
      return {
        true: true,
        false: false,
      }
    },
    values() {
      let result = window.eagleLodash.cloneDeep(this.defaultValues)
      if(!this.formDataItem.values) return result
      if(this.formDataItem.values.true != undefined) result.true = this.formDataItem.values.true
      if(this.formDataItem.values.false != undefined) result.false = this.formDataItem.values.false
      return result
    },
  },
  watch: {
    data: {
      deep: true,
      handler() {
        if(window.eagleLodash.isEqual(this.data, this.storeData)) return
        this.sync()
      },
    },
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>
